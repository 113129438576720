import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "evalDisplay",
    "editForm",
    "evalListItem",
    "newEvalForm",
    "runAgainSpinner",
    "serverEvalRunSpinner",
    "editDeleteButtons",
  ];

  declare readonly evalDisplayTarget: HTMLElement;
  declare readonly editFormTarget: HTMLElement;
  declare readonly evalListItemTarget: HTMLElement;
  declare readonly newEvalFormTarget: HTMLFormElement;
  declare readonly runAgainSpinnerTarget: HTMLElement;
  declare readonly serverEvalRunSpinnerTarget: HTMLElement;
  declare readonly editDeleteButtonsTarget: HTMLElement;

  connect() {}

  showEditForm() {
    this.evalDisplayTarget.classList.add("hidden");
    this.editDeleteButtonsTarget.classList.add("hidden");
    this.editFormTarget.classList.remove("hidden");
  }

  hideEditForm() {
    this.evalDisplayTarget.classList.remove("hidden");
    this.editDeleteButtonsTarget.classList.remove("hidden");
    this.editFormTarget.classList.add("hidden");
  }

  toggleAddForm() {
    this.newEvalFormTarget.classList.toggle("hidden");
  }

  afterAdd(event: Event) {
    const trigger = event.target as HTMLElement;
    if (trigger.matches("form")) {
      this.toggleAddForm();
    }
  }

  afterUpdate(event: Event) {
    const trigger = event.target as HTMLElement;
    if (trigger.matches("form")) {
      this.hideEditForm();
    }
  }

  clearForm() {
    const form = this.newEvalFormTarget;
    form.reset(); // Reset the form
    // Manually clear the form fields
    form.querySelectorAll("input, textarea").forEach((input) => {
      if (input instanceof HTMLInputElement || input instanceof HTMLTextAreaElement) {
        input.value = "";
      }
    });
  }

  showRunAgainSpinner() {
    this.runAgainSpinnerTarget.style.display = "inline";
  }

  hideRunAgainSpinner() {
    this.runAgainSpinnerTarget.style.display = "none";
  }

  showServerEvalRunSpinner() {
    this.serverEvalRunSpinnerTarget.style.display = "inline";
  }

  hideServerEvalRunSpinner() {
    this.serverEvalRunSpinnerTarget.style.display = "none";
  }
}
